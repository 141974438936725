.Eroare{
    display: flex;
    margin: 100px;
    justify-content: center;
    flex-direction: column ;
    align-items: center;
}

.Eroaretext{
    width: 80vw;
    height: 51px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
}