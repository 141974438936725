.avatarPhoto {
  width: 28px;
  border-radius: 32px;
  height: 28px;
  margin: 0;
  flex-shrink: 0;
}

.avatarBg {
  display: flex;
  align-items: center;
  margin:0;
}

.avatarText {
  color: var(--black);
  margin: 0 10px;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.avatarPhotoBig {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    flex-shrink: 0;
}
