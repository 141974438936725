.votingContainer {
    margin:2rem auto;
    width: 75%;
    overflow:hidden;
    padding:50px;
    box-shadow: 0px 4px 4px var(--light-gray);
    border-radius:16px;
}

.ver {
    position: absolute;
    padding-left: 10px;
    user-select: none;  
    bottom: 10px;
    z-index: -1;
    color: lightgray;

}

@media screen and (max-width: 768px) {
    .votingContainer {
        padding:25px;
        margin-bottom:0px;
        padding-bottom:0px;
        box-shadow: none;
        overflow:visible;
    }

    .ver {
        position: absolute;
        text-align: center;
        user-select: none;
        width: 100%;
        bottom: 10px;
        z-index: -1;
        color: lightgray;
    
    }
}
