.overlay {
    backdrop-filter: blur(2px);
    width: 100%;
    height: 100%;
    position: absolute;
    overflow-y: scroll;
}

.popupMembersContainer {
    background-color: var(--white);
    width:50%;
    display:flex;
    flex-direction: column;
    align-items: center ;
    margin:6rem auto;
    padding:50px;
    border-radius:32px;
}

.popupMembersButtonsContainer {
    display:flex;
    justify-content: space-around;
    width:100%;
}

.popupMembersText {
    font-size:1.75rem;
    margin:0.75rem 0;
    text-align: center;
}

@media screen and (max-width: 768px) {

    .popupMembersContainer {
        width:100%;
        height:100vh;
        margin-top:0rem;
        border-radius:0px;
        padding:0px;
        justify-content: center;
    }

    .popupMembersButtonsContainer {
        flex-direction: column;
        align-items: center;
    }
}