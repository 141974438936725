.headerElement {
  width: 100%;
  position: relative;
  padding-left: 15px;
}

.headerInput {
  margin-top: 20px;
  color: var(--black);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: transparent;
  border: none;
}

.headerInput::placeholder {
    color: var(--light-grey);
}

.headerInput:focus {
    outline: none;
}

.redHeaderLine {
    height:1px;
    width:30%;
    position:relative;
    left:-100%;
    background-color:var(--eestec-red);
    overflow: hidden;
  height: 1px;
  width: 130%;
  position: relative;
  left: -100%;
  background-color: var(--eestec-red);
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .headerElement {
  }

  .redHeaderLine {
    width: 150%;
    left: -100%;
  }
}
