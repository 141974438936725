.votingCardContainer {
    position:relative;
}

.voteButtonsContainer > * {
    margin: 1.5rem 0rem;
}

.onlyOneOption {
    color:var(--dark-gray);
    margin-bottom:.5rem;
}

.ballotQuestion {
    font-size:1.25rem;
    font-weight:600;
    margin-bottom:.5rem;
    font-family:"Lato";
}

.sendVoteButtonContainer {
    width:100%;
    display:flex;
    justify-content: flex-end;
}

@media screen and (max-width: 768px) {
    
    
    .voteButtonsContainer {
        display: grid;
        place-items: center;
    }

    .onlyOneOption {
        font-size: 0.8rem;
        margin-bottom:.5rem;
    }

    .sendVoteButtonContainer {
        justify-content: center;
        margin-top:1.5rem;
    }
}