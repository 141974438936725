@media only screen and (min-width: 768px) {
    .historyVoteCardWrapper {
        width: 400px;
        max-width: 500px;
        height: 120px;
        border-radius: 8px;
        border: solid var(--light-gray) 2px ;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 52px;
        margin-left: 120px;
    }

    .historyVoteCardTitle, .historyVoteCardDescription {
        font-size: 1.1rem;
        font-weight: bold;
        margin-bottom: 10px;
        margin-left: 12px;
    }

    .historyVoteCardDescription {
        width: 60%;
        max-height: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: 'Lato';
        font-size: .7rem;
        font-weight: 500;
        margin-top: 0px;
    }

    .trashWrapper {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        position: absolute;
        right: 36px;
        top: 24px;
        background-color: var(--light-gray);
    }

    .trashWrapper:hover {
        cursor: pointer;
    }

    .trashIcon {
        height: 18px;
    }
}

@media only screen and (max-width: 768px) {
    .historyVoteCardWrapper {
        width: 87%;
        max-width: 500px;
        height: 82px;
        border-radius: 8px;
        border: solid var(--light-gray) 2px ;
        position: relative;
    }

    .historyVoteCardTitle, .historyVoteCardDescription {
        font-size: 1.1rem;
        font-weight: bold;
        margin: 11px 0px 0px 11px;
    }

    .historyVoteCardDescription {
        width: 60%;
        max-height: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: 'Lato';
        font-size: .7rem;
        font-weight: 500;
        margin-top: 7px;
    }

    .trashWrapper {
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        position: absolute;
        right: 5px;
        top: 5px;
        background-color: var(--light-gray);
    }

    .trashWrapper:hover {
        cursor: pointer;
    }

    .trashIcon {
        height: 13px;
    }
}