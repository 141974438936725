.membersTable {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(auto-fill, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: center;
  justify-content: center;
  border-style:solid;
  border-width:1px;
  border-radius:15px;
  border-color:var(--light-gray);
  width:100%;
  margin:auto;
  background-color: var(--white);
  max-height:70vh;
  overflow-y:scroll;
  box-shadow: 0px 4px 4px var(--light-gray);
}
.membersTable > div {
    display:grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(1,1fr);
    margin: 0.75rem 0;
}

.membersTable > div > * {
    display:flex;
    align-items:center;
    justify-content:center;
}

.membersTableHead {
    position:sticky;
    top:0px;
    background-color: var(--white);
    width:100%;
    height:65%;
    border-bottom:var(--eestec-red) solid 1px;
    z-index:2;
    font-size:24px;
    text-align: center;
    padding:0;
    margin:0rem;
}

.membersTableHead h2 {
    font-weight:500;
    margin:.25rem 0rem;
}


.membersTableHeadWrapper:hover {
    cursor:pointer; 
}



.arrowDownTable {
    height: 20px;
    margin-left: 20px;
}

@media screen and (max-width: 768px) {

    .membersTableHead h2 {
        font-size:18px;
        font-weight:400 !important;
    }
}