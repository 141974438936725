.floatingButton {
    display: flex;
    flex-direction: row;
    background-color: #e52a30;
    height: fit-content;
    width: fit-content; 
    border: #e52a30;
    border-radius: 12px;
    position: fixed;
    bottom: 28px;
    align-items: center;
    justify-content: center;
    cursor:pointer;
}

.floatingButtonNoText {
    /* aspect-ratio: 1 / 1; */
    width:50px;
    height: 50px;
    border-radius: 50%;
    display:flex;
    align-items: center;
    justify-content: center;
}

.floatingButtonNoText > .floatingButtonIconPlaceHolder {
    transform:translateX(-15%);
}

.floatingButtonIconPlaceHolder {
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:10px;
    text-align: center;
    padding-right:5px;
}

.floatingButtonIconPlaceHolder:last-child {
    padding:10px;
}

.floatingButtonSignOutTextPlaceHolder {
    height: 100%;
    width: 66%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right:10px;
}

.floatingButtonSignOutText {
    font-family: Roboto;
    font-weight:bold;
    font-size: 14px;
    color: white;
    width:60px;
}

.floatingButtonIcon {
    color: #efe7eb;
    height: 24px;
    width: 24px;
}

@media screen and (max-width: 768px) {
    .floatingButton {
        width: fit-content;
    }
    .floatingButtonSignOutTextPlaceHolder {
        width: 99%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding:8px;
    }

    .floatingButtonIconPlaceHolder {
        padding:10px;
    }
    .floatingButtonNoText > .floatingButtonIconPlaceHolder {
        transform:translateX(-15%);
    }
}
