
.memberStatusButtonContainer {
    position:relative;
}



.memberStatusButtonChangeContainer {
    position:absolute;
    top:-20px;
    display:flex;
    flex-direction: column;
    background-color: var(--white);
    padding:5px;
    border-radius:10px;
    border-width:2px;
    border-style:solid;
    border-color:var(--light-grey);
    z-index: 5;
}

.memberStatusButtonChangeContainer > button {
    margin:0.25rem 0;
}



.changeButtonJunior {
    color:var(--green);
    border-color:var(--green);
}

.changeButtonSenior {
    color:var(--eestec-red);
    border-color:var(--estec-red);
}

.changeButtonAlumni {
    color:var(--burgundy);
    border-color:var(--burgundy);
}




