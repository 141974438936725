.voteStatusButton {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border:0px;
    border-bottom:2px solid black;
    font-weight:700;
    padding:0px 5px;
    background-color: #EFE7EB;
    margin-top: 10px;
}

.voteStatusModalContainer {
    width:25%;
    margin:0 auto;
    display:flex;
    justify-content: center;
    flex-direction: column;
    background-color: var(--white);
    padding:15px;
    border-radius:15px;

}

.voteStatusModalHeader {
    font-weight:700;
}

.voteStatusModalButton {
    width:100%;
    display:flex;
    justify-content: center;
}

.voteStatusModalVotersContainer {
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    width:100%;
}

.voteStatusModalVoters {
    padding: 0rem 3rem;
    max-height:75vh;
    overflow-y:scroll;
}

.voteStatusButton {
    margin-left:10%;
}

.editBallotVotesCount {
    margin-left:10%;

}

.editBallotButtonsContainer {
    width:100%;
    display:flex;
    justify-content:space-evenly;
}

.voteStatusModalVotersStatute {
    margin-top:.5rem;
    font-weight:bold;
}

.voteStatusModalVoters > p {
    margin-top:.25rem;
    margin-bottom:0;

}

@media screen and (max-width: 768px) {
    .voteStatusModalContainer {
        width:100%;
        height:100vh;
        border-radius:0px;
    }

    .editBallotButtonsContainer {
        flex-direction: column;
        align-items:center;
    }
}