.createVotePageCard {
    margin: 20px auto;
}

.createVotePagePill {
    position: relative;
    margin-left: calc(50vw - 166px);
    width: fit-content;
}

.createVotePageSend {
    position: relative;
    margin-left: calc(50vw - 140px);
    margin-top: 50px;
}