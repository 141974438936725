@media only screen and (min-width: 768px) {
    .voteHistoryPageWrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .voteCardWrapper {
        width: 80%;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;       
    }
}
@media only screen and (max-width: 768px) {
    .voteCardWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 15px;
    }
}