.userManagementContainer {
    width:65%;
    margin:auto;
    overflow:hidden;
}

@media screen and (max-width: 768px) {
    .userManagementContainer {
        width:90%;
        overflow:visible;
    }
}