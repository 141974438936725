.sendButton {
    background-color: #e52a30;
    height: 55px;
    width: 270px;
    border-radius: 12px;
    border: 1px solid #e52a30;
    box-shadow: 0px 1.666306734085083px 3.332613468170166px rgba(0, 0, 0, 0.25);
    cursor:pointer;
}

.sendButtonIcon {
    color: #efe7eb;
    height: 18px;
    width: 18px;
    margin-right: 5px;
}

.sendButtonLabel {
    color: #efe7eb;
    font-size: 20px;
    font-family: Roboto;
    font-weight: 600;
    line-height: 19px;
    word-wrap: break-word;
}
