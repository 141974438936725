.voteButton {
  display: flex;
  width: 293px;
  padding: 24px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--light-grey, #b9b9b9);
  background: var(--white, #efe7eb);
  cursor: pointer;
  /* shadow */
  box-shadow: 0px 1.66631px 3.33261px 0px rgba(0, 0, 0, 0.25);
}

.voteText {
  color: var(--dark-grey, #444);
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}

.votePressed {
  border: 1px solid var(--eestec-red, #e52a30);
  background: var(--eestec-red, #e52a30);
}

.voteTextPressed {
  color: var(--white);
}
