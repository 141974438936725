.voteFormBG {
  margin-top: 100px;
  position: relative;
  width: 700px;
  height: fit-content;
  flex-shrink: 0;
  padding-bottom: 300px;
  border-radius: 10px;
  background: var(--white);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
}

.voteHBar {
  position: relative;
  width: calc(104% - 30px);
  height: 1px;
  background-color: var(--eestec-red);
  flex-shrink: 0;
  top: 60px;
}

.questionInput {
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: var(--black);
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.questionInput::placeholder {
  color: var(--light-grey);
}

.questionInput:focus {
  outline: none;
}

.voteOptions {
  display: flex;
  flex-direction: column;
  margin: 30px;
}

.optionInput {
  background-color: transparent;
  border: none;
  color: var(--black);
  width: 90%;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.optionInput.disabled {
  color: var(--light-grey) !important;
}

.optionInput1 {
  display: flex;
  flex-direction: row;
}

.optionInput::placeholder {
  color: var(--light-grey);
  border-bottom: 2px solid var(--light-grey);
  width: 60%;
}

.optionInput:focus {
  outline: none;
}

.voteOption {
  display: flex;
  flex-direction: row;
}


@media screen and (max-width: 768px){

.voteFormBG {
  position: relative;
  width: 331px;
  height: fit-content;
  flex-shrink: 0;
  padding-bottom: 20px;
  border-radius: 10px;
  background: var(--white);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
}

.voteHBar {
  position: absolute;
  width: calc(100% - 30px);
  height: 1px;
  background-color: var(--eestec-red);
  flex-shrink: 0;
  left: 0;
  top: 60px;
}

.questionInput {
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: var(--black);
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 10px;
}

.questionInput::placeholder {
  color: var(--light-grey);
}

.questionInput:focus {
  outline: none;
}

.voteOptions {
  display: flex;
  flex-direction: column;
  margin: 30px;
}

.voteItemName {
  display: inline;
}

.voteItemName {
  color: var(--black);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.optionInput {
  background-color: transparent;
  border: none;
  color: var(--black);
  width: 80%;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.optionInput:focus {
  outline: none;
}

.voteOption {
  display: flex;
  flex-direction: row;
}
.disabled {
  color: var(--light-grey);
}}

input[type=radio]{
  pointer-events: none;
}
