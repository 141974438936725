.defaultButton {
    width: 268px;
    height: 55px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    margin-top: 12px;
    margin-bottom: 12px;
    cursor:pointer;
}

.cancelButton {
    border: 1px solid var(--black);
    background-color: var(--white);
    color: #444444;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
}

.yesButton {
    background-color: #e52a30;
    border: 1px solid #e52a30;
    color: #efe7eb;
    font-size: 20px;
    font-family: Roboto;
    font-weight: 600;
    line-height: 19px;
}
