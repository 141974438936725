.resultsPageContainer {
  width: 75vw;
  height: 65vh;
  margin: 0 auto;
  margin-top: 10vh;
  box-shadow: 0px 4px 4px var(--light-gray);
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 50px;
  border-radius: 16px;
}

.resultsPageBodyContainer {
}

.resultsTypeButton {
  margin-left: 0px;
}

.resultsPageVoteCount {
  margin: 0.5rem 0;
}

.resultsPageOptionsContainer {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.copyResultsButton {
  background-color: var(--eestec-red);
  margin-top:.5rem;
  padding:10px;
  border-radius: 12px;
  font-size:1.1rem;
  color:var(--white);
  display:flex;
  cursor:pointer;
  border:0px;
  transition: all .1s ease;
}

.copyResultsButton:hover {
  /* transform:scale(1.02); */
  
}

.copyResultsButton:active {
  background-color: var(--burgundy);
}

.copyResultsButton > span {
  margin-left:.5rem;
}

@media screen and (max-width: 768px) {
  .resultsPageContainer {
    padding: 5px;
    width: 100vw;
    height: 100vh;
  }

  .resultsTypeButton {
    margin: 10px auto;
  }

  .copyResultsButton {
    display:none;
  }
}
