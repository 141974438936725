.memberStatusButton {
    background-color:var(--white);
    padding:8px 12px;
    border:1px solid;
    border-radius:12px;
    width:112px;
    height:40px;
    border-color:var(--green);
    color:var(--green);
    font-size:16px;
    font-weight:bold;
    cursor:pointer;
}