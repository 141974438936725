.searchBar {

    border-radius:12px;
    border-width:1px;
    border-style:solid;
    border-color:var(--light-gray);
    box-shadow: 0px 4px 4px var(--light-gray) ;
    padding:12px 0px 12px 36px;
    font-size:16px;
    width:60%;
    min-width:250px;
    display:block;
    background:no-repeat 5px 10px url("../../../../public/icons/search.png");
    background-size:24px;
    background-color:var(--white);
    font-weight:500;
    color:var(--black);
    min-width:400px;
    margin:4rem 0rem;
}


@media screen and (max-width: 768px) {

    .searchBar {
        width:90%;
        min-width:50px;
        border-radius: 16px;
        padding:8px 0 8px 36px;
        background:no-repeat 5px 5px url("../../../../public/icons/search.png");
        background-size:24px;
        margin:2rem auto;
    }
}