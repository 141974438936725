.unpushedButton {
    width: 166px;
    height: 47px;
    border: 1px solid #b9b9b9;
    background-color: #efe7eb;
    box-shadow: 0px 1.666306734085083px 3.332613468170166px rgba(0, 0, 0, 0.25);
    cursor:pointer;
}

.borderLeftButton {
    border-radius: 12px 0px 0px 12px;
}

.borderRightButton {
    border-radius: 0px 12px 12px 0px;
}

.unpushedButtonIcon {
    height: 16px;
    width: 20px;
    color: #444444;
    margin-right: 5px;
}

.unpushedButtonLabel {
    color: #444444;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 600;
    line-height: 19px;
    word-wrap: break-word;
    margin-left: 5px;
}

.pushedButton {
    width: 166px;
    height: 47px;
    background-color: #e52a30;
    border: 1px solid #e52a30;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) inset;
}

.pushedButtonIcon {
    height: 16px;
    width: 20px;
    color: #efe7eb;
}

.pushedButtonLabel {
    color: #efe7eb;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 600;
    line-height: 19px;
    word-wrap: break-word;
}

