.sendVoteButton {
    background-color: var(--eestec-red);
    border-radius:12px;
    padding:24px 24px;
    color:var(--white);
    font-size:1.25rem;
    display:flex;
    gap:10px;
    width:293px;
    border:none;
    justify-content: center;
    cursor:pointer;
    box-shadow: 0px 1.66631px 3.33261px 0px rgba(0, 0, 0, 0.25);
}

.sendVoteButton > svg {
    margin-top:4px;
}
