.result-skill-bar {
  height: 8px;
  background-color: var(--light-grey);
  border-radius: 8px;
  width: 100%;
}

.result-skill-per {
  height: 8px;
  background: var(--eestec-red);
  border-radius: 8px;
  width: 0;
  transition: width 1s linear;
  position: relative;
}
