.body-mobile {
  background: var(--EESTEC-Red, #e52a30);
  position: relative;
}
body {
  height: 100%;
  /* overflow-y: hidden; */
}
@media screen and (max-width: 768px) {
  .logoEESTEC {
    display: flex;
    /* user-select: none; */
    width: 201px;
    height: 142px;
    justify-content: center;
    align-items: center;
    bottom: 0;
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .card {
    display: flex;
    width: 300px;
    padding: 60px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 20%);
    border-radius: 16px;
    background: var(--White, #efe7eb);
  }

  .titleCard {
    color: var(--Black, #1c191a);
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

@media screen and (min-width: 769px) {
  .logoEESTEC {
    display: flex;
    /* user-select: none; */
    width: 201px;
    height: 142px;
    justify-content: center;
    align-items: center;
    bottom: 0;
    position: fixed;
    left: 25%;
    transform: translate(-50%, -50%);
  }

  .card {
    display: flex;
    width: 300px;
    padding: 60px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    align-items: center;
    top: 50%;
    left: 25%;
    transform: translate(-50%, 50%);
    border-radius: 16px;
    background: var(--White, #efe7eb);
  }

  .titleCard {
    color: var(--Black, #1c191a);
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .ecranAlb {
    display: flex;
    width: 50%;
    height: 100vh;

    position: absolute;
    top: -15px;
    right: 0;
    /* transform: translate(-50%, 50%); */
    background: var(--White, #efe7eb);
  }
  .pozeLista {
    position: absolute;
    margin: 0;
    padding: 0;
    margin-left: 15%;
  }

  .pozeLista li {
    text-align: center;
    display: inline-block;
    list-style: none;
    position: relative;
    border: solid 12px #fff;
    background: #fff;
    box-shadow: 0 0 15px 0px #555;
    transition: all 1s ease;
    -o-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -webkit-transition: all 1s ease;
    top: 50px;
    left: 50px;
  }

  li:nth-child(1) {
    transform: rotate(20deg);
    left: 30vh;
  }

  li:nth-child(3) {
    transform: rotate(-10deg);
    top: -10vh;
  }

  li:nth-child(4) {
    transform: rotate(-20deg);
  }

  li:nth-child(5) {
    transform: rotate(20deg);
    top: -20vh;
    left: 30vh;
  }

  li:nth-child(7) {
    transform: rotate(-20deg);
  }
}
