.resultsCardbBg {
  display: flex;
  width: 293px;
  padding: 16px 36px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;

  border-radius: 8px;
  border: 1px solid var(--light-grey);
  background: var(--white);

  /* shadow */
  box-shadow: 0px 1.66631px 3.33261px 0px rgba(0, 0, 0, 0.25);
}

.resultsCardTitleProc {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.resultTextBlack {
  color: var(--black);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.resultVoteNumber {
  color: var(--light-grey);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.resultsReverseArrow {
  transform: scaleY(-1);
}

.resultsSeeMore {
  display: flex;
  align-items: center;
}